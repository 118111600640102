<template>
  
    
    <div class="dashboard__container">
      
      <!-- <div class="dashboard__container--body" v-if="eventInfo">
        <div class="eventdays mb-3">
          <button class="mr-2 mb-2" @click="showStaff()" v-bind:class="{ 'chipDark': !activeDay, 'chip': activeDay }">
            All Employees
          </button>
          <button class="mr-2 mb-2" v-for="(day, index) in eventInfo.days" :key="index" @click="showEventShfts(day)" v-bind:class="{ 'chipDark': activeDay == day, 'chip': activeDay != day }">
            {{day}}
          </button>
        </div>
      </div> -->
      <div class="dashboard__container--body">
        <PayrollTable v-if="userPayroll && userPayroll.length >= 1" :eventAssignments="userPayroll" :eventUsersRef="[user]" />
        
      </div>

    </div>
</template>

<style scoped type="text/css">
  input {
    padding: 0 !important;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import PayrollTable from '@/components/PayrollTable.vue'
import router from '@/router'
import ExportService from "@/services/ExportService"
import TimesheetNote from '@/components/Timesheets/TimesheetNote.vue'
import * as moment from 'moment'

const fb = require('../../firebaseConfig.js')

export default {
  props: ['user', 'dnr'],
  name: 'userPayroll',
  data: () => ({
    performingRequest: false,
    // encryptionKey: 'SKD433{}{[SKD433{}{[SKD433{}{[32',
    isVisible: true,
    isHidden: false,
    isPaid: false,
    activeItem: null,
    selected: [],
    activeDay: '',
    statuses: ['completed', 'no-show', 'no-response', 'arrived late', 'left early', 'client fired', 'terminated' ],
    paystatuses: ['paid', 'paid tips', 'paid hours', 'not paid' ],
    columns2: [
      {
        label: 'Date',
        field: 'day',
        width: '100px',
      },
      {
        label: 'First',
        field: 'firstName',
        width: '100px',
      },
      {
        label: 'Last',
        field: 'lastName',
        width: '100px',
      },
      {
        field: 'locked',
        type: 'boolean',
        tdClass: 'text-center',
        sortable: false,
      },
      // {
      //   field: 'delete',
      //   sortable: false,
      // },
      {
        field: 'link',
        sortable: false,
      },
      {
        label: 'Social',
        field: 'fileId',
        width: '100px',
      },
      {
        field: 'note',
        sortable: false,
      },
      {
        label: 'Confirmed',
        field: 'confirmed',
        sortable: false,
      },
      {
        label: 'Pay Status',
        field: 'paystatus',
        width: '140px',
        sortable: false,
      },
      {
        label: 'Event Status',
        field: 'status',
        width: '140px',
        sortable: false,
      },
      
      
      {
        label: 'Position',
        field: 'position',
      },
      
      {
        label: 'Rate',
        field: 'regRate',
        width: '64px',
        sortable: false,
      },
      {
        label: 'In',
        field: 'checkInTimeStamp',
        width: '64px',
        sortable: false,
      },
      {
        label: 'Out',
        field: 'checkOutTimeStamp',
        width: '64px',
        sortable: false,
      },
      {
        label: 'Hours',
        field: 'regHours',
        width: '64px',
        sortable: false,
      },
      {
        label: 'OT',
        field: 'otHours',
        width: '64px',
        sortable: false,
      },
      {
        label: '2OT',
        field: 'ot2Hours',
        width: '64px',
        sortable: false,
      },
      {
        label: 'Penalty',
        field: 'mbp',
        width: '64px',
        sortable: false,
      },
      {
        label: 'Tips',
        field: 'tips',
        width: '64px',
      },
      {
        label: 'Bonus',
        field: 'dayRate',
        width: '64px',
        sortable: false,
      },
      {
        label: 'Save',
        field: 'save',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState(['userPayroll']),
  },
  components: {
    Loader,
    TimesheetNote,
    PayrollTable
  },
  methods: {
    // goBack() {
    //   router.go(-1)
    // },
    // goEvents() {
    //   let url = `/users/` + this.$route.params.id + `/assignments`
    //   router.push(url)
    // },
    // goPayroll() {
    //   let url = `/users/` + this.$route.params.id + `/payroll`
    //   router.push(url)
    // },
    // decryptedText() {
    //   if (this.userProfile.ssn) {
    //   return (this.$CryptoJS.AES.decrypt(this.userProfile.ssn, this.encryptionKey).toString(this.CryptoJS.enc.Utf8) || this.$CryptoJS.AES.encrypt(this.ssn, this.encryptionKey).toString())
    //   }
    // },
    showHidden() {
      this.isHidden = true
      this.isVisible = false
      this.isPaid = false
    },
    showVisible() {
      this.isVisible = true
      this.isHidden = false
      this.isPaid = false
    },
    showPaid() {
      this.isPaid = true
      this.isVisible = false
      this.isHidden = false
    },
    showStaff() {
      this.performingRequest = true
      this.activeDay = ''
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
    formatDateCalc(q) {
      if(q) {
        const postedDate = new Date(q) * 1000;
        console.log(postedDate)
        let time = new Date(postedDate).toISOString().substr(11, 8)
        console.log(time)
        let hours = moment.duration(time).asHours()
        return hours
      } else {
        return null
      }
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('HH:mm:ss A')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
    formatDate2(q) {
      return moment(q).format('HH:MM A')
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }

      return date;
    },
    onUpdatePay(row) {
      this.$store.dispatch('updateTimesheetPay', {
        id: row.id,
        paystatus: row.paystatus
      })
    },
    onUpdateStatus(row) {
      this.$store.dispatch('updateTimesheetStatus', {
        id: row.id,
        status: row.status
      })
    },
    showNote(r) {
      console.log(r)
      this.activeItem = r
    },
    closeNote(r) {
      this.activeItem = null
    },
    removeEntry(row) {
      fb.assignmentsCollection.doc(row.id).update({
        hidden:true
      })
    },
    showEntry(row) {
      fb.assignmentsCollection.doc(row.id).update({
        hidden:false
      })
    },
    onSheetEditable(row)  {
      row = row
      row.editable = true
      this.$store.dispatch('updateTimesheet', row)
    },
    onSheetEdit(row) {
      row = row
      row.editable = false
      this.$store.dispatch('updateTimesheet', row)
    },
    lock(item) {
      fb.usersCollection.doc(item.userId).get()
      .then(
        doc => {
          console.log(doc.id, " => ", doc.data())
          fb.assignmentsCollection.doc(item.id).update({ 
            locked: true, 
            fileId: doc.data().ssn || null
          })
        }
      )
    },
    unlock(item) {
      fb.assignmentsCollection.doc(item.id).update({ locked: false })
    },
    // showEventShfts(day) {
    //   let url = `/timesheets/` + this.$route.params.id + `/` + day
    //   router.push(url)
    // },
    showEventShfts(day) {
      this.performingRequest = true
      this.activeDay = day
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
    onRowClick(params) {
      let url = `/timesheets/` + params.row.id
      console.log(url)
      router.push(url)
    },
    lockAll(shift) {
      let event = this.event
      // this.$refs[shift.id][0].selectedRows[0].status = "haha"
      // console.log(this.$refs[shift.id][0].selectedRows)
      // this.$refs[shift.id][0].selectedRows.forEach(row => {
      //   this.$store.dispatch("lockTheShift", {
      //     shift: shift, 
      //     event: event, 
      //     row: row
      //   })
      // })
      this.selected.forEach(row => {
        row.status = "spinning"
      })
      this.$store.dispatch("lockTheShifts", {
        event: event,
        rows: selected,
        shift: shift,
        // shiftStart: this.formatAMPM(shift.startTime) ,
        // shiftEnd:this.formatAMPM(shift.endTime)
      })
      // this.$refs[shift.id][0].selectedRows = []
      
    },
    exportReportEmp2() {
      let logFields = {
          user: this.currentUser.email,
          export: 'Payroll Export',
      }
      this.$store.dispatch('sendExportLog', logFields)
      const exportHeaders = [
        "first_name",
        "last_name",
        "type",
        "id",
        "ssn",
        "hours",
        "rate",
        "treat_as_cash",
        "event",
        "venue_id",
        "wc_code"
      ];
      const exportItems = [];
      console.log(this.selected)
      for (var key in this.selected) {
        let uid = this.selected[key].userId
        let regRate = this.selected[key].regRate
        let regHours = this.selected[key].regHours
        let otHours = this.selected[key].otHours
        let ot2Hours = this.selected[key].ot2Hours
        let mbp = this.selected[key].mbp
        let tips = this.selected[key].tips
        let event = this.selected[key].name
        let dayRate = this.selected[key].dayRate
        let venueId = this.selected[key].eventInfo.venueId

         fb.usersCollection.doc(uid).get()

          .then(doc => {
          
          let wcCode

          if (this.selected[key].eventInfo.venue.address.state =('AL'||'AK'||'AR'||'CO'||'CT'||'FL'||'GA'||'HI'||'ID'||'IL'||'IN'||'IA'||'KS'||'KY'||'MN'||'MO'||'MS'||'MT'||'NE'||'NV'||'NC'||'NH'||'NM'||'OK'||'OR'||'RI'||'SC'||'SD'||'TN'||'UT'||'VT'||'WI'||'WV')){
            wcCode = '9082'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('AZ'||'LA'||'VA')){
             wcCode = '9083'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('CA'||'TX')){
            wcCode ='9079'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('DE'|| 'PA')){
            wcCode ='9045'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('ME')){
            wcCode ='9084'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('MD')){
            wcCode ='9086'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('MI'||'NY')){
            wcCode ='9058'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('MA')){
            wcCode ='9085'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('ND'|| 'OH'|| 'WA'|| 'WY')){
            wcCode ='9082OC'
          }
          else if (this.selected[key].eventInfo.venue.address.state =('NJ')){
            wcCode ='9078'
          }

          
          var social
          if (doc.data().ssn) {
            social = (this.$CryptoJS.AES.decrypt(doc.data().ssn, this.encryptionKey).toString(this.CryptoJS.enc.Utf8))
          }

          exportItems.push([
            doc.data().firstName || '',
            doc.data().lastName || '',
            "1",
            "7",
            social || '',
            "1",
            dayRate || '',
            "1",
            event || '',
            venueId,
            wcCode || ''
          ]);
          exportItems.push([
            doc.data().firstName || '',
            doc.data().lastName || '',
            "1",
            "1",
            social || '',
            regHours || '',
            regRate || '',
            "0",
            event,
            venueId,
            wcCode || ''
          ]);
          exportItems.push([
            doc.data().firstName || '',
            doc.data().lastName || '',
            "1",
            "2",
            social || '',
            otHours || '',
            regRate * 1.5 || '',
            "0",
            event,
            venueId,
            wcCode || ''
          ]);
          exportItems.push([
            doc.data().firstName || '',
            doc.data().lastName || '',
            "1",
            "22",
            social || '',
            ot2Hours || '',
            regRate * 2 || '',
            "0",
            event,
            venueId,
            wcCode || ''
          ]);
          exportItems.push([
            doc.data().firstName || '',
            doc.data().lastName || '',
            "1",
            "125",
            social || '',
            "1",
            tips || '',
            "1",
            event,
            venueId,
            wcCode || ''
          ]);
          exportItems.push([
            doc.data().firstName || '',
            doc.data().lastName || '',
            "1",
            "4",
            social || '',
            "0",
            mbp || '',
            "0",
            event,
            venueId,
            wcCode || ''
          ]);
          this.$gapi.getGapiClient().then(gapi => {
            const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
            exportService.export();
          });
        })

      }
      
      // fb.shiftsCollection.doc(this.shift.id).update({ exportedEmp: fb.firestore.FieldValue.serverTimestamp() })
      setTimeout(() => {
        // this.$store.dispatch("getShiftFromId", this.$route.params.id)
        this.performingRequest = false
      }, 2000)
    },
    selectionChanged(params) {
      this.selected = params.selectedRows
    }
  },
  beforeDestroy () {
    this.columns = null
    delete this.columns
    // this.$store.dispatch("clearUserPayroll")
    // this.$store.dispatch('clearErrors')
  }
}
</script>